export const accordionItems = [
  {
    header: "What is Scholar Earth?",
    body: "Scholar Earth (SE) is a one-stop platform for students who wish to pursue their education abroad. We provide end to end assistance to students, from career counseling and shortlisting universities to getting student visas, education loans, accommodation, and part-time jobs. We endeavor to place all our students successfully in their dream universities. We follow a unique approach to counselling in which our team of experienced consultants work with students individually to make sure they get all the necessary help, make the right decisions for their careers, and ultimately achieve their dreams.",
  },
  {
    header: "Is Scholar Earth free?",
    body: "Most services at Scholar Earth are free, including career counseling by our experts, a database of 100s of universities with courses, and application reviews. However, there are certain paid services which students can optionally avail themselves of. Register and speak to our career counselors for free now who will guide you through the process.",
  },
  {
    header: "What is the advantage of Scholar Earth?",
    body: "Scholar Earth is the only platform that provides personalized individual guidance to our students. We also provide a state-of-the-art technology platform in which you can get all possible help and services, from researching universities, to getting queries for all your questions and attending events.",
  },
  {
    header: "How can I get my questions answered or receive help?",
    body: "Our expert consultants will set time with you individually to guide you through your education planning and the application process. They will answer all your questions and queries. In addition, when you register, you will get free access to our database of 100s of universities and courses so that you can do your research and also a FAQ database consisting of answers to all important questions.",
  },
];
