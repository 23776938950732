import React from "react";
import { Row } from "react-bootstrap";
import PageTitle from "../../atoms/page_title";

const AboutUs = () => {
  return (
    <div className="container pt-4 pb-lg-5 pb-4">
      <PageTitle title={"About Us"} />
      <Row className="mx-auto pt-4">
        <div className="col-12 d-flex flex-lg-row flex-column">
          <div className="col-lg-6 col-12 align-self-center">
            <img
              className="about-img"
              src="/images/aboutmain.jpg"
              alt="Generic placeholder image"
            />
          </div>
          <div className="col-lg-6 col-12 text-center text-lg-start ps-lg-5  about-content pt-4 pt-lg-0">
            <p className="mt-0 lh-lg fs-18  fw-normal pb-0 mb-0 pb-lg-4">
              Congratulations on your decision to study abroad. As you embark on
              this life-changing journey, Scholar Earth will be your trusted
              mentor, guiding you with every step in the process so that your
              dream becomes reality.
            </p>
            <p className="lh-lg fs-18  fw-normal pb-0 mb-0 pb-lg-4">
              Studying abroad is appealing, rewarding, and a wonderful
              experience. Nevertheless, securing that coveted spot in your dream
              university demands careful planning and hard work. We make things
              simple for you by providing an extensive suite of innovative
              products on a single platform to meet all your study abroad
              requirements.
            </p>
            <p className="lh-lg fs-18  fw-normal m-0 ">
              We are committed to our vision of providing strong and equitable
              educational opportunities to everyone and are looking forward to
              assisting you in your quest for knowledge and growth.
            </p>
          </div>
        </div>
      </Row>
    </div>
  );
};

export default AboutUs;
