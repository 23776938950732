"use client";
import React from "react";
import { Homepage } from "../src/home/banner-homepage";
const Page = () => {
  return (
    <>
      <Homepage />
    </>
  );
};

export default Page;
