import React from "react";
import Image from "next/image";
const OurServiceCard = ({ img, title, desc }) => {
  return (
    <div className="card px-2 pt-2 shadow-sm rounded">
      <Image
        src={img}
        alt="loading..."
        layout="responsive"
        loading="lazy"
        width={100}
        height={100}
      />
      <div className="card-body py-3 px-1 text-center">
        <p className="card-text fs-18 mb-1  fw-bolder">{title}</p>
        <h6 className="fw-normal px-3 fs-6">{desc}</h6>
      </div>
    </div>
  );
};

export default OurServiceCard;
