import React from "react";
import { servicesData } from "../../../constants/service_Data";
import PageTitle from "../../atoms/page_title";
import OurServiceCard from "../../organisms/our_service_card/index";

const ServicePage = () => {
  return (
    <section className="bg-light">
      <div className="container pt-4 pb-5">
        <PageTitle title={"Our Services"} />
        <div className="container d-flex flex-column gap-lg-5 gap-3 pt-lg-4 pt-3  ">
          <div className="event-grid-wrapper">
            {servicesData.map((item, idx) => {
              return (
                <OurServiceCard
                  key={idx}
                  img={item.img}
                  title={item.title}
                  desc={item.desc}
                />
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServicePage;
