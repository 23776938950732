import Header from "../../organisms/landing_pages/header";
import { Footer } from "../../organisms/landing_pages/footer";
import RootLayout from "../../../../app/layout";
import { Toaster } from "sonner";
import {CookiesModal} from "../../organisms/modal/cookie-modal";

export const Layout = ({ children, pageTitle }) => {
  return (
    <RootLayout pageTitle={pageTitle}>
      <Header />
      {children}
      <CookiesModal />
      <Toaster position="top-center" invert={true} duration={3000} />

      <Footer />
    </RootLayout>
  );
};
