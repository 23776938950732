import React from "react";
import { Bannerpage } from "../components/organisms/landing_pages/banner_page";
import AboutUs from "../components/organisms/landing_pages/about";
import Testimonials from "../components/organisms/landing_pages/testimonials";
import FaqPage from "../components/organisms/landing_pages/faq_page";
import ServicePage from "../components/organisms/landing_pages/service_section";
import OurPartners from "../components/organisms/landing_pages/our_partners";
import { Layout } from "../components/organisms/layout/Layout";

export const Homepage = () => {
  return (
    <Layout pageTitle={"Scholar Earth"}>
      <Bannerpage />
      <ServicePage />
      <AboutUs />
      <Testimonials />
      <FaqPage />
      <OurPartners />
    </Layout>
  );
};
