import React, { useState } from "react";
import Slider from "react-slick";
import { IoMdClose } from "react-icons/io";
import Modal from "react-modal";
import Image from "next/image";
const VideoSlider = ({ videos }) => {
  const [selectedVideo, setSelectedVideo] = useState(null);

  let reviews = {
    dots: false,
    infinite: true,
    loop: true,
    speed: 1500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <div>next</div>,
    prevArrow: <div>prev</div>,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          arrows: true,
        },
      },
    ],
  };

  const handleVideoClick = (videoId) => {
    setSelectedVideo(videoId);
  };

  const handleCloseModal = () => {
    setSelectedVideo(null);
  };

  const generateThumbnail = (link) => {
    // debugger;
    var youtube_video_id = link.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/).pop();
    let src = "https://img.youtube.com/vi/"+youtube_video_id+"/0.jpg"
    return  src
  }

  return (
    <>
      <Slider {...reviews} className="video_testimonial">
        {videos.map((video, index) => (
          <div key={index} className="slider-item">
            <div className="col-lg-12" onClick={() => handleVideoClick(video)}>
              <div className="ratio ratio-16x9 image-container">
                {index==0 && <Image
                  src={generateThumbnail(video.video_link)}
                  alt={video.given_by}
                  layout="responsive"
                  className="shadow-1-strong rounded image"
                  loading="lazy"
                  width={100}
                  height={100}
                />}
                {index!=0 && <iframe
                className="shadow-1-strong rounded"
                src={video.video_link}
                title={video.given_by}
                allowFullScreen
              ></iframe>}
                 <div className="overlay">
                  
                </div> 
              </div>
            </div>
          </div>
        ))}
      </Slider>

      {selectedVideo && (
        <Modal
          className="modalwrapper"
          isOpen={true}
          onRequestClose={handleCloseModal}
        >
          <button
            data-toggle="tooltip"
            data-placement="top"
            title="Close"
            onClick={handleCloseModal}
            className="modalClose cursor-pointer"
          >
            <IoMdClose />
          </button>
          <div className="col-12 p-0">
            <div className="ratio ratio-16x9">
              <iframe
                className="shadow-1-strong rounded"
                src={selectedVideo.video_link}
                title={selectedVideo.given_by}
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default VideoSlider;
