import React from "react";
import PageTitle from "../../atoms/page_title";
import { partnerImages ,partnerMobileImages} from "../../../constants/our_partnerImg";
const OurPartners = () => {
  const firstRow = partnerImages.slice(0, 7);
  const lastRow = partnerImages.slice(7, 10);
  return (
    <section className="bg-white mb-5">
      <div className="container">
        <div className="row">
          <div className="text-center pb-lg-2 display-4 fw-normal newsReaderFont">
            <PageTitle title={"Universities Placed In"} />
          </div>
          <div className="partner_logos mt-4">
            {firstRow.map((partner, index) => (
              <img key={index} src={partner.src} alt={partner.alt} />
            ))}
          </div>
          <div className="partner_logos1 mt-4">
            {lastRow.map((partner, index) => (
              <img key={index} src={partner.src} alt={partner.alt} />
            ))}
          </div>

          <div className="partner_logos_mobile mt-4">
            {partnerMobileImages.map((partner, index) => (
              <img key={index} src={partner.src} alt={partner.alt} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurPartners;
