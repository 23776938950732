import React, { useEffect, useState } from "react";
import { getTestimonials } from "../../../api/video_testimonials"
import VideoSlider from "../videoModal/video_modal";
import PageTitle from "../../atoms/page_title";


const Testimonials = () => {
  const [testimonials, setTestimonials] = React.useState([])
  useEffect(()=>{
   getTestimonials()
    .then((res) => {
      
        if (res) {
          //setLoading(false);
          setTestimonials(res.data);
        } else {
          console.error("Response is null or undefined");
        }
      })
      .catch((error) => {
        //setLoading(false);
        console.error("Error fetching data:", error);
      });
  },[])

  return (
    <>
    {testimonials.length !== 0 && (
    <section className="bg-light">
      <div className="container pt-4 pb-5">
        <div className="text-center pb-4 mb-2 display-4 fw-normal newsReaderFont">
          <PageTitle title={"Video Essays"} />
          <p className="fs-18 m-0">
            They’re living the dream, and you can too !
          </p>
        </div>
        <div className="video_card">
          <VideoSlider videos={testimonials} />
        </div>
      </div>
    </section>
    )}
    </>
  );
};

export default Testimonials;
