import axios from "axios";

export const getTestimonials = async () => {
  // debugger;
  try {
    const axiosInstance = axios.create({
        baseURL: `${process.env.NEXT_PUBLIC_BASE_URL}`,
      });
    const { data } = await axiosInstance.get(
      "/api/v1/counselling/video-testimonials/",
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return data;
  } catch (error) {
    console.error("Error while sending request:", error);
    throw error; 
  }
};
