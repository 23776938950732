import React from "react";

const PageTitle = ({ title, className, textStart }) => {
  return (
    <h5
      className={`${
        textStart || "text-center"
      } display-5 fw-normal newsReaderFont m-0  ${className}`}
    >
      {title}
    </h5>
  );
};

export default PageTitle;
