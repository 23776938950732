import Image from "next/image";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { ACCESS_TOKEN } from "../../../constants/common";

export const Bannerpage = () => {
  const [token, setToken] = useState(null);
  const [isTokenLoading, setisTokenLoading] = useState(true);
  useEffect(() => {
    setisTokenLoading(true);
    const storedToken = localStorage.getItem(ACCESS_TOKEN);
    setToken(storedToken);
    setisTokenLoading(false);
  }, [token]);

  return (
    <section>
      <div className="container">
        <Row className="banner-wrapper pt-5 pb-4 mb-3 py-lg-5 mb-lg-0">
          <div className="col-12 px-3 d-flex flex-lg-row flex-column-reverse">
            <div className="col-lg-5 col-12 pe-lg-5 text-center text-md-start align-self-center pt-4 pt-lg-0">
              <div className=" text-center  newsReaderFont">
                <h1 className="display-4 fw-normal newsReaderFont">
                  Turn your<i className="newsReaderFont"> Global</i> <br />
                  <i className="newsReaderFont">Education Dreams </i> into
                  reality
                </h1>
                <p className="fs-4 fw-normal interFont mb-2">
                  as have 100s of other dreamers...
                </p>
                {isTokenLoading ? null : (
                  <>
                    {" "}
                    {token ? (
                      <p className="pt-2 m-0">
                        <Link
                          className="btn bg-greenish  text-white  px-4 py-2"
                          href="/university-finder"
                        >
                          Search Universities
                        </Link>
                      </p>
                    ) : (
                      <p className="pt-2 m-0">
                        <Link
                          className="btn bg-greenish  text-white  px-4 py-2"
                          href="/login"
                        >
                          Free Sign Up Now
                        </Link>
                      </p>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="col-lg-7 col-12">
              <Image
                alt="loading..."
                layout="responsive"
                loading="lazy"
                width={100}
                height={100}
                src="/images/mainbanner.jpg"
              />
            </div>
          </div>
        </Row>
      </div>
    </section>
  );
};
