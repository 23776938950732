export const servicesData = [
  {
    title: "Shortlist Colleges",
    img: "/images/about.jpg",
    desc: "Search for the right courses across 100s of global universities",
  },
  {
    title: "Crack IELTS/TOEFL",
    img: "/images/ilets.jpg",
    desc: "Get comprehensive test preparation with expert guidance and material for IELTS, TOEFL, PTE, GMAT, GRE, and SAT",
  },
  {
    title: "Financial Planning",
    img: "/images/financial.jpg",
    desc: "Secure affordable education loans through our partners in easy hassle-free steps",
  },
  {
    title: "Application Review ",
    img: "/images/app.jpg",
    desc: "Put your best foot forward with application review by our expert counselors",
  },
  {
    title: "Visa Application ",
    img: "/images/visasa.jpg",
    desc: "Secure your visa with our best-in-class visa assistance service and enjoy exclusive benefits from our international travel partners",
  },
  {
    title: "Career Planning",
    img: "/images/career.jpg",
    desc: "Prepare to soar in your dream university through our career planning services, including acing your studies, accommodation, and part time jobs",
  },
];
