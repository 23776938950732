import React from "react";
import { Row } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
import { accordionItems } from "../../../constants/faqData";
import PageTitle from "../../atoms/page_title";
import Image from "next/image";
const FaqPage = () => {
  return (
    <section className="bg-light mb-4">
      <div className="container pt-4  pb-5">
        <div className="text-center pb-lg-4 pb-3 pb-0 display-4 fw-normal newsReaderFont">
          <PageTitle title={"Q&A"} />
        </div>
        <div>
          <div className="accordian-wrapper">
            <Row>
              <div className="col-12 px-3  d-flex flex-lg-row flex-column-reverse">
                <div className="col-lg-7 col-12 text-center text-lg-start pe-lg-5 pt-4 pt-lg-0">
                  <Accordion>
                    {accordionItems.map((item, index) => (
                      <Accordion.Item key={index} eventKey={index.toString()}>
                        <Accordion.Header>{item.header}</Accordion.Header>
                        <Accordion.Body>{item.body}</Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </div>
                <div className="col-lg-5 col-12 pt-3 pt-lg-0">
                  <Image
                    src="/images/q_a.jpg"
                    alt="loading..."
                    layout="responsive"
                    loading="lazy"
                    width={100}
                    height={100}
                  />
                </div>
              </div>
            </Row>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaqPage;
