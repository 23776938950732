export const partnerImages = [
  { src: "/images/partner3.png", alt: "Arizona State University" },
  { src: "/images/partner9.png", alt: "California State University" },
  { src: "/images/partner4.png", alt: "University College Dublin" },
  { src: "/images/partner7.png", alt: "University of Galway" },
  { src: "/images/partner8.png", alt: "Texas A&M University" },
  { src: "/images/partner1.png", alt: "University of Hull" },
  { src: "/images/partner5.png", alt: "UWE Bristol" },
  { src: "/images/partner6.png", alt: "University of Bristol" },
  { src: "/images/partner2.png", alt: "Coventry University" },
  { src: "/images/partner10.png", alt: "University of Queensland" },
];

export const partnerMobileImages = [
  { src: "/images/partner2.png", alt: "Coventry University" },
  { src: "/images/partner7.png", alt: "University of Galway" },
  { src: "/images/partner3.png", alt: "Arizona State University" },
  { src: "/images/partner9.png", alt: "California State University" },
  { src: "/images/partner4.png", alt: "University College Dublin" },
  { src: "/images/partner8.png", alt: "Texas A&M University" },
  { src: "/images/partner1.png", alt: "University of Hull" },
  { src: "/images/partner5.png", alt: "UWE Bristol" },
  { src: "/images/partner6.png", alt: "University of Bristol" },

  { src: "/images/partner10.png", alt: "University of Queensland" },
];
